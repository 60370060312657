/** @jsxImportSource theme-ui */
import React from "react"
import { Box, Divider, Flex, Heading, Text } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import {
  footerStyles,
  socialLinkStyles,
  dividerStyles,
  logoStyles,
  boxStyles,
  headingStyles,
  hoursTextStyles,
  siteMapLinkStyles,
} from "./footer-1-Styles"
import { DEALERSHIP_NAME, HOURS, TAGLINE } from "../../config/strings"
import { Link } from "gatsby"

const siteMapLinks = [
  { name: "Home", path: "/" },
  { name: "Inventory", path: "/inventory" },
  { name: "Financing", path: "/financing" },
  { name: "Contact", path: "/contact" },
]

const SiteMapLink = ({ name, path }) => (
  <Link sx={siteMapLinkStyles} to={path}>
    {name}
  </Link>
)

const FooterSection = ({ title, children }) => (
  <Box sx={boxStyles}>
    <Heading sx={headingStyles}>{title}</Heading>
    <Divider sx={dividerStyles} />
    {children}
  </Box>
)

const Footer1 = () => (
  <Box as="footer" sx={footerStyles}>
    <FooterSection title={DEALERSHIP_NAME}>
      <Text as="h4">{TAGLINE}</Text>
    </FooterSection>

    <FooterSection title="Site Map">
      <Flex sx={{ flexWrap: "wrap" }}>
        {siteMapLinks.map(link => (
          <SiteMapLink key={link.name} name={link.name} path={link.path} />
        ))}
      </Flex>
    </FooterSection>

    <FooterSection title="Hours of Operation">
      <Flex sx={{ flexWrap: "wrap" }}>
        {HOURS.map((item, index) => (
          <Box key={index} sx={hoursTextStyles}>
            <Text as="h4">
              {item.day} {item.hours}
            </Text>
          </Box>
        ))}
      </Flex>
    </FooterSection>
  </Box>
)

export default Footer1
